/*
  Flaticon icon font: Flaticon
  Creation date: 09/03/2019 08:57
*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Flaticon.woff2") format("woff2"),
    url("../fonts/Flaticon.woff") format("woff"),
    url("../fonts/Flaticon.ttf") format("truetype"),
    url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  font-style: normal;
  font-family: Flaticon;
}

.flaticon-add:before {
  content: "\f100";
}
.flaticon-right-alignment:before {
  content: "\f101";
}
.flaticon-cancel-music:before {
  content: "\f102";
}
.flaticon-musica-searcher:before {
  content: "\f103";
}
.flaticon-apple:before {
  content: "\f104";
}
.flaticon-apple-1:before {
  content: "\f105";
}
.flaticon-google-play:before {
  content: "\f106";
}
.flaticon-checked:before {
  content: "\f107";
}
.flaticon-paper-plane:before {
  content: "\f108";
}
.flaticon-startup:before {
  content: "\f109";
}
.flaticon-air-freight:before {
  content: "\f10a";
}
.flaticon-quotation:before {
  content: "\f10b";
}
