/* 14 - pricing plan page - start
==================================================================================================== */
.pricing-section {
	.bg-image {
		margin: 0 auto;
		max-width: 1690px;
		position: relative;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		.pricing-plan {
			margin-top: -200px;
		}
	}

	.pricing-tab-nav {
		margin-bottom: 270px;
		>ul {
			display: table;
			border-radius: 3px;
			background-color: $pure-white;
			border: 2px solid $default-blue;
			>li {
				width: 50%;
				float: left;
				height: 40px;
				display: inline-block;
				>a {
					width: 100%;
					height: 100%;
					display: block;
					font-size: 16px;
					padding: 0px 30px;
					line-height: 40px;
					color: $default-color;
					&:hover {
						color: $pure-white;
						background-color: $default-blue;
					}
				}
				>.active {
					color: $pure-white;
					background-color: $default-blue;
				}
			}
		}
	}

	.pricing-plan {
		padding: 50px 30px;
		border-radius: 5px;
		position: relative;
		border: 1px solid $deep-gray;
		background-color: $pure-white;
		transition: $default-transition;
		.offer-text {
			top: 45px;
			right: 0px;
			font-size: 14px;
			padding: 8px 10px;
			border-radius: 3px;
			position: absolute;
			color: $pure-white;
			background-color: $default-pink;
			&:before {
				left: 0px;
				bottom: -10px;
				content: '';
				position: absolute;
				border-top: 10px solid transparent;
				border-left: 10px solid $default-pink;
				border-right: 10px solid transparent;
				border-bottom: 10px solid transparent;
			}
		}
		.plan-title {
			font-size: 18px;
			color: $default-color;
		}
		.item-icon {
			width: 120px;
			height: 115px;
			font-size: 54px;
			line-height: 115px;
			text-align: center;
			background-repeat: no-repeat;
			background-position: center center;
			>i {
				background: linear-gradient(to bottom, #e93194, #701d94);
				-webkit-background-clip: text !important;
				-webkit-text-fill-color: transparent !important;
			}
		}
		.price-text {
			font-size: 36px;
			color: $default-blue;
			font-weight: $fw-bold;
		}
		.item-list {
			padding: 50px 0px;
			>ul {
				>li {
					font-size: 16px;
					margin-bottom: 15px;
					&:last-child {margin-bottom: 0px;}
				}
			}
		}
		.start-btn {
			width: auto;
			height: 55px;
			font-size: 16px;
			padding: 0px 45px;
			line-height: 55px;
			border-radius: 3px;
			color: $default-color;
			background-color: $light-gray;
		}
		&:hover {
			box-shadow: 0px 15px 43.65px 1.35px rgba(0, 27, 103, 0.1);
			.start-btn {
				color: $pure-white;
				background-color: $default-blue;
			}
		}
	}

	.pricing-plan.active-item {
		box-shadow: 0px 15px 43.65px 1.35px rgba(0, 27, 103, 0.1);
		.start-btn {
			color: $pure-white;
			background-color: $default-blue;
		}
		&:hover {
			.start-btn {background-color: $default-pink;}
		}
	}

	.review-btn {
		margin-top: 70px;
		.custom-btn-underline {
			font-size: 18px;
			color: $pure-white;
			&:before {border-bottom: 2px solid $pure-white;}
		}
	}
}
/* 14 - pricing plan page - end
==================================================================================================== */